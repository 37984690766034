import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
	desc?: string;
	descId?: string;
}
const Trash = ({ title, titleId, desc, descId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		fill="none"
		aria-labelledby={titleId}
		aria-describedby={descId}
		{...props}
	>
		{desc ? <desc id={descId}>{desc}</desc> : null}
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			stroke="#293455"
			strokeLinecap="round"
			strokeLinejoin="round"
			d="m19 8-.867 12.142A2 2 0 0 1 16.138 22H7.862a2 2 0 0 1-1.995-1.858L5 8m5 4v6m4-6v6m1-10V5a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v3M4 8h16"
		/>
	</svg>
);
export default Trash;
