import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
	desc?: string;
	descId?: string;
}
const ShoppingBagOutline = ({
	title,
	titleId,
	desc,
	descId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={25}
		fill="none"
		aria-labelledby={titleId}
		aria-describedby={descId}
		{...props}
	>
		{desc ? <desc id={descId}>{desc}</desc> : null}
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			stroke={props.color || '#293455'}
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M16 11.835v-4a4 4 0 1 0-8 0v4m-3-2h14l1 12H4l1-12Z"
		/>
	</svg>
);
export default ShoppingBagOutline;
