'use client';

import React, { createContext, useContext, useEffect, useState } from 'react';

type MobileOverlay = {
	showMobileOverlay: boolean;
	setMobileOverlayVisibility: (arg1: boolean) => void;
};

type MobileOverlayContext = MobileOverlay;

const Context = createContext({} as MobileOverlayContext);

export const MobileOverlayProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [showMobileOverlay, setMobileOverlayVisibility] = useState<boolean>(false);

	useEffect(() => {
		if (showMobileOverlay) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	}, [showMobileOverlay]);

	return (
		<Context.Provider value={{ showMobileOverlay, setMobileOverlayVisibility }}>
			{children}
		</Context.Provider>
	);
};

type UseMobileOverlayContext = () => MobileOverlayContext;

export const useMobileOverlayContext: UseMobileOverlayContext = () => useContext(Context);
