'use client';

import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import type { Theme, ThemeContextType } from './types';

import canUseDOM from '../../_utilities/canUseDOM';
import { defaultTheme, getImplicitPreference, themeLocalStorageKey } from './shared';
import { themeIsValid } from './types';

const initialContext: ThemeContextType = {
	setTheme: () => null,
	theme: undefined,
};

const ThemeContext = createContext(initialContext);

export const ThemeProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
	const [theme, setThemeState] = useState<Theme | undefined>(
		canUseDOM ? (document.documentElement.getAttribute('data-theme') as Theme) : undefined,
	);

	const setTheme = useCallback((themeToSet: Theme | null) => {
		if (themeToSet === null) {
			window.localStorage.removeItem(themeLocalStorageKey);
			const implicitPreference = getImplicitPreference();
			document.documentElement.setAttribute('data-theme', implicitPreference || '');
			if (implicitPreference) setThemeState(implicitPreference);
		} else {
			setThemeState(themeToSet);
			window.localStorage.setItem(themeLocalStorageKey, themeToSet);
			document.documentElement.setAttribute('data-theme', themeToSet);
		}
	}, []);

	useEffect(() => {
		const themeToSet: Theme = defaultTheme;
		//const preference = window.localStorage.getItem(themeLocalStorageKey);

		/* 		if (themeIsValid(preference)) {
			themeToSet = preference;
		} else {
			const implicitPreference = getImplicitPreference();

			if (implicitPreference) {
				themeToSet = implicitPreference;
			}
		} */

		document.documentElement.setAttribute('data-theme', themeToSet);
		setThemeState(themeToSet);
	}, []);

	return <ThemeContext.Provider value={{ setTheme, theme }}>{children}</ThemeContext.Provider>;
};

export const useTheme = (): ThemeContextType => useContext(ThemeContext);
