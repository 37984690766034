import type { CookieConsentConfig } from 'vanilla-cookieconsent';
import { FullStory, init as initFullStory, isInitialized } from '@fullstory/browser';

function clearFullStoryCookies() {
	const cookiesToDelete = [
		'fs_uid',
		'fs_session',
		'fs_csrftoken',
		'fs_trusted_device',
		'fs_last_activity',
		'fs_cid',
		'_fs_tab_id',
		'fs_lua',
	];

	// Function to delete a cookie on a specific domain
	function deleteCookie(name: string, domain: string) {
		document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain};`;
	}

	// Iterate over each cookie name and delete it across relevant domains
	cookiesToDelete.forEach(cookieName => {
		// Delete from the current domain
		deleteCookie(cookieName, window.location.hostname);

		// Delete from the primary FullStory domain
		deleteCookie(cookieName, '.fullstory.com');

		// Delete from your own domain (e.g., .ebs.ee)
		deleteCookie(cookieName, process.env.NEXT_PUBLIC_FS_COOKIE_DOMAIN || '');
	});

	// Stop FullStory recording if it's running
	const isFullstoryInitialized = isInitialized();
	if (isFullstoryInitialized) {
		FullStory('shutdown');
	}
}

const pluginConfig: CookieConsentConfig = {
	guiOptions: {
		consentModal: {
			layout: 'box',
			position: 'bottom left',
			equalWeightButtons: true,
			flipButtons: false,
		},
		preferencesModal: {
			layout: 'box',
			position: 'left',
			equalWeightButtons: true,
			flipButtons: false,
		},
	},

	// onFirstConsent: function () {
	// 	console.log('onFirstAction fired');
	// },

	onConsent: function ({ cookie }) {
		if (cookie.categories.includes('analytics')) {
			initFullStory({
				orgId: 'o-20P31B-na1',
				cookieDomain: process.env.NEXT_PUBLIC_FS_COOKIE_DOMAIN,
			});
		}
	},

	onChange: function ({ changedCategories, cookie }) {
		if (!cookie.categories.includes('analytics')) {
			clearFullStoryCookies();
		}
	},

	categories: {
		necessary: {
			readOnly: true,
			enabled: true,
		},
		analytics: {
			autoClear: {
				cookies: [
					{
						name: /^(fs_uid|fs_session|fs_csrftoken|fs_trusted_device|fs_last_activity|fs_cid|_fs_tab_id|fs_lua)/,
					},
				],
			},
		},
	},

	language: {
		default: 'et',
		autoDetect: 'browser',
		translations: {
			et: {
				consentModal: {
					title: 'EBS e-pood kasutab küpsiseid',
					description:
						'Meie veebisait kasutab jälgimisküpsiseid, et mõista, kuidas te sellega suhtlete. Jälgimine lubatakse ainult siis, kui te sellega otseselt nõustute. <a href="#privacy-policy" data-cc="show-preferencesModal" class="cc__link">Halda eelistusi</a>',
					acceptAllBtn: 'Luba kõik',
					acceptNecessaryBtn: 'Luba ainult vajalikud',
					showPreferencesBtn: 'Halda eelistusi',
					footer: `
            <a href="/documents/privacy-policy">Privaatsuspoliitika</a>
          `,
				},
				preferencesModal: {
					title: 'Küpsiste eelistused',
					acceptAllBtn: 'Luba kõik',
					acceptNecessaryBtn: 'Luba ainult vajalikud',
					savePreferencesBtn: 'Salvesta eelistused',
					sections: [
						{
							title: 'Küpsiste kasutamine',
							description:
								'Kasutame küpsiseid, et tagada veebisaidi põhifunktsioonid ja parandada teie veebikogemust. Lisateabe saamiseks küpsiste ja muu tundliku teabe kohta lugege <a href="/documents/privacy-policy" class="cc__link">EBS e-poe privaatsuspoliitikat</a>.',
						},
						{
							title: 'Rangelt vajalikud küpsised',
							description:
								'Rangelt vajalikud küpsised on küpsised, milleta meie veebileht ei funktsioneeri. Funktsioneerimiseks vajalik on salvestada teie keelevalik ning küpsiste valik.',
							linkedCategory: 'necessary',
						},
						{
							title: 'Tõhususe ja analüüsi küpsised',
							linkedCategory: 'analytics',
							cookieTable: {
								headers: {
									name: 'Nimi',
									domain: 'Teenuse pakkuja',
									description: 'Kirjeldus',
									expiration: 'Aegumisaeg',
								},
								body: [
									{
										name: 'fs_uid',
										domain: 'FullStory',
										description:
											'Kasutatakse kasutaja jälgimiseks sessioonide ja lehtede lõikes, et tagada kogu salvestatud sessiooniliikluse seostamine ühe kasutajaga. See küpsis anonümiseerib kasutaja külastuse logimise eesmärgil.',
										expiration: 'Aegub pärast 1 aastat',
									},
									{
										name: 'fs_session',
										domain: 'FullStory',
										description:
											'Autentitud kasutaja sessiooni säilitamine FullStory veebirakenduses. See küpsis on mõeldud ainult FullStory administraatoritele ja kasutajatele, mitte lõppkasutajatele.',
										expiration: '30 päeva',
									},
									{
										name: 'fs_csrftoken',
										domain: 'FullStory',
										description:
											'Kasutatakse cross-site request forgery (CSRF) rünnakute vältimiseks. See küpsis on mõeldud ainult FullStory administraatoritele ja kasutajatele, mitte lõppkasutajatele.',
										expiration: '30 päeva',
									},
									{
										name: 'fs_trusted_device',
										domain: 'FullStory',
										description:
											'Takistab kasutajatel iga sisselogimiskatse korral oma seadme kinnitamist. See küpsis on mõeldud ainult FullStory administraatoritele ja kasutajatele, mitte lõppkasutajatele.',
										expiration: '60 päeva',
									},
									{
										name: 'fs_last_activity',
										domain: 'FullStory',
										description:
											'Salvestab viimase tegevuse ajatempliga veebirakenduses, et aidata sessioonide ajalõppudega. See küpsis on mõeldud ainult FullStory administraatoritele ja kasutajatele, mitte lõppkasutajatele.',
										expiration: 'Aegub, kui sessioon/browser suletakse',
									},
									{
										name: 'fs_cid',
										domain: 'FullStory',
										description:
											'Salvestab selle seadme nõusoleku oleku. See küpsis määrab kasutaja nõusoleku oleku.',
										expiration: 'Aegub pärast 1 aastat',
									},
									{
										name: '_fs_tab_id',
										domain: 'FullStory',
										description:
											'Toetab mitme vahekaardi esitamist, andes igale vahekaardile ainulaadse ID. Seda salvestatakse sessionStorage’is, mitte küpsises.',
										expiration: 'Aegub, kui vahekaart suletakse',
									},
									{
										name: 'fs_lua',
										domain: 'FullStory',
										description:
											'Salvestab viimase kasutajategevuse ajatempliga, et aidata FullStory sessiooni elutsüklis. Tagab, et kasutajategevus pikendab sessiooni.',
										expiration: 'Aegub pärast 30 minutit',
									},
								],
							},
						},
						{
							title: 'Rohkem informatsiooni',
							description:
								'Kui teil on küpsiste ja teie valikutega seotud küsimusi, võtke meiega ühendust aadressil support@ebs.ee.',
						},
					],
				},
			},
			en: {
				consentModal: {
					title: 'EBS online store uses cookies',
					description:
						'Our website uses tracking cookies to understand how you interact with it. Tracking will only be enabled if you explicitly agree. <a href="#privacy-policy" data-cc="show-preferencesModal" class="cc__link">Manage preferences</a>',
					acceptAllBtn: 'Allow all',
					acceptNecessaryBtn: 'Allow only necessary',
					showPreferencesBtn: 'Manage preferences',
					footer: `
            <a href="/documents/privacy-policy">Privacy Policy</a>
          `,
				},
				preferencesModal: {
					title: 'Cookie Preferences',
					acceptAllBtn: 'Allow all',
					acceptNecessaryBtn: 'Allow only necessary',
					savePreferencesBtn: 'Save preferences',
					sections: [
						{
							title: 'Cookie Usage',
							description:
								'We use cookies to ensure the basic functionalities of the website and to enhance your web experience. For more information about cookies and other sensitive data, please read the <a href="/documents/privacy-policy" class="cc__link">EBS online store privacy policy</a>.',
						},
						{
							title: 'Strictly necessary cookies',
							description:
								'Strictly necessary cookies are essential for the website to function. It is necessary to save your language selection and cookie preferences.',
							linkedCategory: 'necessary',
						},
						{
							title: 'Performance and Analytics Cookies',
							linkedCategory: 'analytics',
							cookieTable: {
								headers: {
									name: 'Name',
									domain: 'Service',
									description: 'Description',
									expiration: 'Expiration',
								},
								body: [
									{
										name: 'fs_uid',
										domain: 'FullStory',
										description:
											'Used to track the user across sessions and pages, ensuring that all captured session traffic is associated with one user. This cookie anonymizes the user’s visit for logging purposes.',
										expiration: 'Expires after 1 year',
									},
									{
										name: 'fs_session',
										domain: 'FullStory',
										description:
											"Maintains an authenticated user's session within the FullStory Web Application. This cookie is specific to admins and users of FullStory, not end users.",
										expiration: '30 days',
									},
									{
										name: 'fs_csrftoken',
										domain: 'FullStory',
										description:
											'Used to prevent cross-site request forgery. This cookie is specific to admins and users of FullStory, not end users.',
										expiration: '30 days',
									},
									{
										name: 'fs_trusted_device',
										domain: 'FullStory',
										description:
											'Prevents users from having to verify their device on every login attempt. This cookie is specific to admins and users of FullStory, not end users.',
										expiration: '60 days',
									},
									{
										name: 'fs_last_activity',
										domain: 'FullStory',
										description:
											'Records the last action timestamp within the web application to assist with session timeouts. This cookie is specific to admins and users of FullStory, not end users.',
										expiration: 'Expires when the session/browser closes',
									},
									{
										name: 'fs_cid',
										domain: 'FullStory',
										description:
											"Stores the consent state for this device. This cookie is used to determine the user's consent state.",
										expiration: 'Expires after 1 year',
									},
									{
										name: '_fs_tab_id',
										domain: 'FullStory',
										description:
											'Supports multi-tab playback by providing a unique ID to each tab. This is stored in sessionStorage rather than a cookie.',
										expiration: 'Expires when the tab is closed',
									},
									{
										name: 'fs_lua',
										domain: 'FullStory',
										description:
											'Captures the timestamp of the last user action to assist with the FullStory session lifecycle. Ensures user activity extends the session.',
										expiration: 'Expires after 30 minutes',
									},
								],
							},
						},
						{
							title: 'More Information',
							description:
								'If you have any questions regarding cookies and your choices, please contact us at support@ebs.ee.',
						},
					],
				},
			},
		},
	},
};

export default pluginConfig;
