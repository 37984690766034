import ShoppingBagOutline from '../../_icons/ShoppingBagOutline';

import { useCart } from '../../_providers/Cart';
import { useEffect, useState } from 'react';

export const CartShoppingBag: React.FC<{
	className?: string;
	type?: 'mobile' | 'desktop';
	asChild?: boolean;
	onClick?: (...args) => void;
}> = ({ asChild, type = 'desktop', ...props }) => {
	const { cart } = useCart();

	const [cartItemsCount, setCartitemsCount] = useState<number>();

	useEffect(() => {
		setCartitemsCount(cart?.cartItems?.length || 0);
	}, [cart]);

	if (type === 'mobile') {
		return (
			<div className="relative" {...props}>
				<span>
					<ShoppingBagOutline />
					{typeof cartItemsCount === 'number' && cartItemsCount > 0 && (
						<small className="absolute -right-[5px] -top-[5px] flex h-4 w-4 transform items-center justify-center rounded-full bg-gold-light p-2 text-[10px] text-white transition ease-in ">
							{cartItemsCount}
						</small>
					)}
				</span>
			</div>
		);
	}

	return (
		<div
			className="relative rounded-2xl bg-gold-light px-3 py-2 text-white hover:bg-gold hover:duration-300 hover:ease-out"
			{...props}
		>
			<span>
				<ShoppingBagOutline color="white" />
				{typeof cartItemsCount === 'number' && cartItemsCount > 0 && (
					<small className="absolute right-[-8px] top-[-8px] flex h-5 w-5 transform items-center justify-center rounded-3xl bg-black p-1 text-xs transition ease-in ">
						{cartItemsCount}
					</small>
				)}
			</span>
		</div>
	);
};
