'use client';

import React, { useEffect, useState, useTransition } from 'react';
import { useTranslations } from 'next-intl';
import ChevronLeft from '../../../_icons/Chevron-left';
import { SlugTranslationsType } from '../../../_api/fetchSlugTranslations';
import { useMobileOverlayContext } from '../../../_providers/MobileOverlay';
import { Button } from '../../Button';
import type { Courseversion, Header as HeaderType } from '../../../../payload/payload-types';
import { Link, useRouter } from '../../../../navigation';
import { CMSLink } from '../../Link';
import { CartItem } from '../../CartItem';
import { useCart } from '../../../_providers/Cart';
import { useAuth } from '../../../_providers/Auth';
import { AuthModal } from '../../AuthModal';
import { LoginForm } from '../../Forms/LoginForm';
import { RegisterForm } from '../../Forms/RegisterForm.jsx';
import { RecoverPasswordForm } from '../../Forms/RecoverPasswordForm.jsx';
import LocaleSwitcher from '../../LocaleSwitcher';
import { CartTotal } from '../../CartSummary';
import { useTrackEvent } from '../../../_providers/TrackEventProvider';

const MobileOverlayClose: React.FC<{
	onClose?: () => void;
}> = ({ onClose }) => {
	const { setMobileOverlayVisibility } = useMobileOverlayContext();
	const t = useTranslations();

	return (
		<div className="mt-14 h-14 w-full bg-white">
			<Button
				className="ml-4 mt-10"
				onClick={() => {
					onClose && onClose();
					setMobileOverlayVisibility(false);
				}}
				variant="ghost"
			>
				<ChevronLeft /> {t('back')}
			</Button>
		</div>
	);
};

const MobileBurgerContent: React.FC<{ navItems: HeaderType['navItems']; close: () => void }> = ({
	navItems,
	close,
}) => {
	if (!navItems) return <div>No navigation items available</div>;
	return (
		<>
			<MobileOverlayClose />
			<div className="flex h-full items-center justify-center">
				<div className="mt-8">
					<ul className="m-10 flex flex-col items-center justify-items-center space-y-8 text-xl">
						{navItems.map(({ link }, i) => {
							return (
								<span key={i} onClick={close}>
									<CMSLink {...link} className="hover:underline" />
								</span>
							);
						})}
					</ul>
				</div>
			</div>
		</>
	);
};

const MobileAccountContent: React.FC<{ close: () => void }> = ({ close }) => {
	const t = useTranslations();
	const { user, logout } = useAuth();
	const router = useRouter();
	type AuthType = 'login' | 'register' | 'recoverPassword';
	const [currentAuthType, setAuthType] = useState<AuthType>('login');

	const performLogout = async (e: React.SyntheticEvent) => {
		e.preventDefault();
		close();
		try {
			await logout();

			router.push('/');
		} catch (_) {}
	};

	return (
		<>
			<MobileOverlayClose
				onClose={() => {
					setAuthType('login');
				}}
			/>
			<div className="flex h-full items-center justify-center">
				<div className="mt-8">
					{!user ? (
						<div>
							{currentAuthType === 'login' ? (
								<LoginForm
									afterSubmit={() => {
										close();
									}}
								/>
							) : currentAuthType === 'register' ? (
								<RegisterForm
									afterSubmit={() => {
										close();
									}}
								/>
							) : (
								<div className="p-6">
									<RecoverPasswordForm
										afterSubmit={() => {
											close();
										}}
									/>
								</div>
							)}
							{currentAuthType === 'login' && (
								<>
									<div className="flex justify-center">
										<p className="mr-3">{t('Auth.forgotPassword')}</p>
										<a
											className="font-semibold text-brand-navy"
											href=""
											onClick={e => {
												e.preventDefault();
												setAuthType('recoverPassword');
											}}
										>
											{t('Auth.recover')}
										</a>
									</div>
									<div className="mt-4 flex justify-center rounded-3xl bg-gold-transparent p-6">
										<p className="mr-3">{t('Auth.no-user')}</p>
										<a
											className="font-semibold text-brand-navy"
											href=""
											onClick={e => {
												e.preventDefault();
												setAuthType('register');
											}}
										>
											{t('Auth.register')}
										</a>
									</div>
								</>
							)}
						</div>
					) : (
						<ul className="m-10 flex flex-col items-center justify-items-center space-y-8 text-xl">
							<Link
								onClick={close}
								className="hover:underline"
								href={'/account/orders'}
							>
								{t('myOrders')}
							</Link>
							<a className="hover:underline" href="" onClick={performLogout}>
								{t('Logout')}
							</a>
						</ul>
					)}
				</div>
			</div>
		</>
	);
};

const MobileCartContent: React.FC<{ close: () => void }> = ({ close }) => {
	const t = useTranslations('Cart');
	const { cart } = useCart();
	const { user } = useAuth();
	const router = useRouter();
	const [isPending, startTransition] = useTransition();
	const { setMobileOverlayVisibility } = useMobileOverlayContext();
	const trackEvent = useTrackEvent();

	return (
		<>
			<MobileOverlayClose />
			<div className="flex h-full items-center px-4">
				<div className="mt-16 w-full">
					<div className="flex flex-col">
						<h2 className="mb-6 text-4xl text-muted-navy">{t('cart')}</h2>
						<div className="space-y-4">
							{!cart?.cartItems?.length && <p>{t('cartEmpty')}</p>}
							{typeof cart?.cartItems?.length === 'number' &&
								cart?.cartItems?.length > 0 &&
								cart.cartItems.map(cartItem => {
									if (
										!cartItem.orderedCollection ||
										typeof cartItem.orderedCollection.value === 'string'
									) {
										return null;
									}

									const item = cartItem.orderedCollection.value as Courseversion;

									return (
										<CartItem
											type="mobile"
											key={`mobileOverlayCart-${item.id}`}
											disableCounter={false}
											item={item}
											cartItem={cartItem}
										></CartItem>
									);
								})}
						</div>
						{!!cart?.cartItems?.length && (
							<div className="mb-8">
								<h2 className="mb-6 mt-16 text-4xl text-muted-navy">
									{t('summary')}
								</h2>
								<div className="space-y-8 rounded-3xl bg-gold-light-8 p-8">
									<CartTotal />

									{!user ? (
										<AuthModal
											authType="login"
											trigger={
												<Button
													onClick={() => {
														trackEvent('checkout_clicked', {});
														trackEvent(
															'login_or_registration_required',
															{},
														);
													}}
													className="mt-10 w-full"
													intent="secondary"
												>
													{t('checkout')}
												</Button>
											}
											afterSubmit={() => {
												startTransition(() => {
													router.push('/checkout');
													trackEvent('authentication_completed', {});
												});
											}}
										></AuthModal>
									) : (
										<Button
											className="w-full"
											intent="secondary"
											onClick={() => {
												setMobileOverlayVisibility(false);
												startTransition(() => {
													router.push('/checkout'); // kui oled checkoutis nii, et sa pole sisselogitud, peaksid ka nägeama seda popupt
													trackEvent('checkout_clicked', {});
												});
											}}
										>
											{t('checkout')}
										</Button>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export const MobileOverlay: React.FC<{
	type: 'cart' | 'burger' | 'account' | 'language' | null;
	navItems: HeaderType['navItems'];
	slugTranslations: SlugTranslationsType;
}> = ({ type, navItems, slugTranslations }) => {
	const { showMobileOverlay, setMobileOverlayVisibility } = useMobileOverlayContext();
	const contentComponents = {
		cart: <MobileCartContent close={() => setMobileOverlayVisibility(false)} />,
		burger: (
			<MobileBurgerContent
				close={() => setMobileOverlayVisibility(false)}
				navItems={navItems}
			/>
		),
		account: <MobileAccountContent close={() => setMobileOverlayVisibility(false)} />,
		language: (
			<>
				<MobileOverlayClose />
				<LocaleSwitcher type="mobile" slugTranslations={slugTranslations} />
			</>
		),
	};

	if (!type) return null;

	return (
		<div className={`${showMobileOverlay ? '' : 'hidden'} relative z-10 h-screen`}>
			<div className="fixed inset-0 w-screen overflow-y-auto overflow-x-hidden bg-white">
				<div>
					<div className="h-full w-full ">{contentComponents[type]}</div>
				</div>
			</div>
		</div>
	);
};
