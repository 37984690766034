'use client';

import React, { useTransition } from 'react';
import { useParams, useSearchParams } from 'next/navigation';
import { useLocale } from 'next-intl';
import { usePathname, useRouter } from '../../../navigation';
import { useTranslations } from 'next-intl';

import { localeMap, locales } from '../../../config';
import { SlugTranslationsType } from '../../_api/fetchSlugTranslations';
import {
	Popover,
	PopoverClose,
	PopoverContent,
	PopoverTrigger,
} from '@shadcn/components/ui/popover';
import { useMobileOverlayContext } from '../../_providers/MobileOverlay';

export const LocaleTrigger = ({ type = 'desktop' }: { type?: 'desktop' | 'mobile' }) => {
	const locale = useLocale();
	if (type === 'mobile') {
		return <>{localeMap[locale]}</>;
	}

	return (
		<PopoverTrigger className="rounded-2xl bg-gold-light px-3 py-2 text-white hover:bg-gold hover:duration-300 hover:ease-out">
			{localeMap[locale]}
		</PopoverTrigger>
	);
};

export default function LocaleSwitcher({
	slugTranslations,
	type = 'desktop',
}: {
	slugTranslations: SlugTranslationsType;
	type?: 'desktop' | 'mobile';
}) {
	const locale = useLocale();
	const router = useRouter();
	const { id, slug, inviteId } = useParams<{
		locale: string;
		slug?: string;
		id?: string;
		inviteId?: string;
	}>();
	const [isPending, startTransition] = useTransition();
	const pathname = usePathname();
	const searchParams = useSearchParams();

	function onChangeLocale(e: React.SyntheticEvent, nextLocale) {
		e.preventDefault();
		startTransition(() => {
			const localizedSlugParam =
				slug && slugTranslations && slugTranslations[slug]?.slugs[nextLocale];
			const query = {};
			for (const [key, value] of searchParams.entries()) {
				query[key] = value;
			}
			router.replace(
				// @ts-expect-error all params must be supported
				{
					pathname,
					params: {
						...(slug ? { slug: localizedSlugParam || slug } : {}),
						...(id ? { id } : {}),
						...(inviteId ? { inviteId } : {}),
					},
					query,
				},
				{
					locale: nextLocale,
				},
			);
		});
	}

	if (type === 'mobile') {
		const { setMobileOverlayVisibility } = useMobileOverlayContext();
		const t = useTranslations('LocaleSwitcher');
		const contentTexts = {
			EST: t('in-estonian'),
			ENG: t('in-english'),
		};

		return (
			<div className="flex h-full items-center justify-center">
				<div className="mt-[144px]">
					<ul className="m-10 flex flex-col items-center justify-items-center space-y-8 text-xl">
						{locales.map(_locale => {
							if (locale === _locale) {
								return null;
							}
							const currentLocale = localeMap[_locale];
							return (
								<a
									key={`mobile-locale-${locale}`}
									className="flex-1 cursor-pointer p-2 hover:text-gold"
									onClick={e => {
										onChangeLocale(e, _locale);
										setMobileOverlayVisibility(false);
									}}
								>
									{contentTexts[currentLocale]}
								</a>
							);
						})}
					</ul>
				</div>
			</div>
		);
	}
	return (
		<Popover>
			<LocaleTrigger type="desktop" />
			<PopoverContent>
				<div className="flex flex-col">
					{locales.map(_locale => {
						if (locale === _locale) {
							return null;
						}
						return (
							<PopoverClose asChild={true} key={`language-${locale}`}>
								<a
									className="flex-1 cursor-pointer p-2 hover:text-gold"
									onClick={e => onChangeLocale(e, _locale)}
								>
									{localeMap[_locale]}
								</a>
							</PopoverClose>
						);
					})}
				</div>
			</PopoverContent>
		</Popover>
	);
}
