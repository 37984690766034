'use client';

import React from 'react';
import { CartDrawer } from '../CartDrawer';

export const CartLink: React.FC<{
	className?: string;
}> = () => {
	return <CartDrawer />;
};
