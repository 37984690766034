import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
	desc?: string;
	descId?: string;
}
const Profile = ({
	title,
	titleId,
	desc,
	descId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		fill="none"
		aria-labelledby={titleId}
		aria-describedby={descId}
		{...props}
	>
		{desc ? <desc id={descId}>{desc}</desc> : null}
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M16 7.24763C16 8.30849 15.5786 9.32591 14.8284 10.0761C14.0783 10.8262 13.0609 11.2476 12 11.2476C10.9391 11.2476 9.92172 10.8262 9.17157 10.0761C8.42143 9.32591 8 8.30849 8 7.24763C8 6.18676 8.42143 5.16935 9.17157 4.4192C9.92172 3.66905 10.9391 3.24763 12 3.24763C13.0609 3.24763 14.0783 3.66905 14.8284 4.4192C15.5786 5.16935 16 6.18676 16 7.24763ZM12 14.2476C10.1435 14.2476 8.36301 14.9851 7.05025 16.2979C5.7375 17.6106 5 19.3911 5 21.2476H19C19 19.3911 18.2625 17.6106 16.9497 16.2979C15.637 14.9851 13.8565 14.2476 12 14.2476Z"
			stroke="black"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default Profile;
