import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
	desc?: string;
	descId?: string;
}
const Burger = ({
	title,
	titleId,
	desc,
	descId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={25}
		fill="none"
		aria-labelledby={titleId}
		aria-describedby={descId}
		{...props}
	>
		{desc ? <desc id={descId}>{desc}</desc> : null}
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2 5.5C2 5.22386 2.23564 5 2.52632 5L21.4737 5C21.7644 5 22 5.22386 22 5.5C22 5.77614 21.7644 6 21.4737 6L2.52632 6C2.23564 6 2 5.77614 2 5.5Z"
			fill="#293455"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M22 12.5C22 12.2239 21.7644 12 21.4737 12L2.52632 12C2.23564 12 2 12.2239 2 12.5C2 12.7761 2.23564 13 2.52632 13L21.4737 13C21.7644 13 22 12.7761 22 12.5Z"
			fill="#293455"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M22 19.5C22 19.2239 21.7644 19 21.4737 19L2.52632 19C2.23564 19 2 19.2239 2 19.5C2 19.7761 2.23564 20 2.52632 20L21.4737 20C21.7644 20 22 19.7761 22 19.5Z"
			fill="#293455"
		/>
	</svg>
);
export default Burger;
