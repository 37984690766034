import React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@shadcn/lib/utils';

const chipVariants = cva('text-white font-bold', {
	variants: {
		size: {
			small: 'text-sm leading-6 py-0.5 px-4 rounded-2xl',
			large: 'text-base leading-8 py-1 px-4 rounded-3xl',
		},
		bgColor: {
			default: 'bg-muted-blue',
			green: 'bg-success',
		},
	},
	defaultVariants: {
		size: 'small',
		bgColor: 'default',
	},
});

type ChipType = {
	size?: 'small' | 'large';
	bgColor?: 'default' | 'green';
	children: React.ReactNode;
	className?: string;
};

export interface ChipProps
	extends React.HTMLAttributes<HTMLParagraphElement>,
		VariantProps<typeof chipVariants> {
	children: React.ReactNode;
}

export const Chip: React.FC<ChipType> = ({ children, size, bgColor, className, ...props }) => {
	return (
		<p className={cn(chipVariants({ size, bgColor, className }))} {...props}>
			{children}
		</p>
	);
};
